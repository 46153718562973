.spin {
	display: flex;
	align-items: center;
	//background: coral;
	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.group {
			width: clamp(64px, 12vh, 160px);
			height: clamp(64px, 12vh, 160px);
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			pointer-events: all;
			&:hover {
				.blur {
					transition: opacity .1s;
					opacity: 1;
				}
			}
			&.disabled {
				pointer-events: none;
				.arrow { opacity: .5; }
				.blur { opacity: .5; }
			}
			.back {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
			.arrow {
				position: absolute;
				width: 60%;
			}
			.blur {
				position: absolute;
				width: 65%;
				opacity: 0;
			}
		}
	}
}