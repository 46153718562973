.bet {
	display: flex;
	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #fdfdfd;
		.label { font-size: clamp(12px, 1.8vh, 18px); }
		.value { font-size: clamp(16px, 3vh, 20px); }
	}
}