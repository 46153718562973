.sound {
	width: clamp(48px, 6vh, 80px);
	height: clamp(48px, 6vh, 80px);
	display: flex;
	background: rgba(253, 253, 253, 0.27);
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	pointer-events: all;
	img { height: 60%; }
}