.layer_gui {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1;
	pointer-events: all;
	&.isDev {
		pointer-events: none;
	}
	.header {
		height: auto;
		padding: clamp(5px, 4vh, 15px) clamp(15px, 5vh, 50px) 0 clamp(15px, 5vh, 50px);
		display: flex;
		//background: burlywood;
		.left {
			height: auto;
			display: flex;
			gap: 48px;
			//background: coral;
		}
		.center {
			width: 100%;
			display: flex;
			justify-content: center;
			//background: #dfff50;
		}
		.right {
			height: auto;
			display: flex;
			gap: 48px;
			//background: #6750ff;
		}
	}
	.body {
		height: 100%;
		padding: 0 clamp(15px, 5vh, 50px) 0 clamp(15px, 5vh, 50px);
		display: flex;
		justify-content: space-between;
		//background: #a1a72a;
		.left {
			//width: 200px;
			margin-top: 2%;
			height: auto;
			display: flex;
			gap: 48px;
			//background: #2a98a7;
			&.land {

			}
		}
		.center {
			width: 100%;
			display: flex;
			justify-content: center;
			//align-items: center;
			//background: #2aa777;
			align-items: end;
			padding-bottom: 3vh;
		}
		.right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10vh;
			//position: absolute;
			//background: #2a8aa7;
			height: 100%;
			right: 0;
			top: 0;
		}
	}
	.footer {
		height: auto;
		padding: 0 clamp(15px, 5vh, 50px) clamp(5px, 4vh, 15px) clamp(15px, 5vh, 50px);
		display: flex;
		//background: burlywood;
		.left {
			height: auto;
			display: flex;
			gap: 48px;
			//background: coral;
		}
		.center {
			width: 100%;
			display: flex;
			justify-content: center;
			//background: #dfff50;
		}
		.right {
			height: auto;
			display: flex;
			gap: 48px;
			//background: #6750ff;
		}
	}
}