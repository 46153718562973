.app {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	background: black;
	canvas {
		position: fixed;
		top: 0;
	}
	.ios_scroll_crunch {
		width: 1px;
		height: 999999px;
	}
}