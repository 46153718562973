.dev {
	width: clamp(48px, 6vh, 80px);
	height: clamp(48px, 6vh, 80px);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	pointer-events: all;
	&.active {
		p {
			color: #d2b338;
			opacity: 1;
		}
	}
	p {
		position: absolute;
		color: #d2b338;
		font-size: 140%;
		filter: drop-shadow(2px 4px 6px black);
		opacity: .3;
	}
}