.spin {
	display: flex;
	align-items: center;
	width: clamp(48px, 22vh, 200px);
	height: clamp(48px, 22vh, 200px);
	justify-content: center;
	cursor: pointer;
	pointer-events: all;
	position: relative;
	background: #a1a72a;
	border-radius: 50%;
	&:active {
		animation: 1s alternate toggle;
	}
	&.disabled {
		pointer-events: none;
		.arrow { opacity: .5; }
		.blur { opacity: .5; }
	}
	.arrow {
		position: absolute;
		width: 60%;
	}
}