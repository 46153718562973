.webgl {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 2vh;
	align-items: center;
	justify-content: center;
	background: black;
	color: #fdfdfd;
	pointer-events: none;
	user-select: none;
}