$icon-size: clamp(48px, 6vh, 80px);
$icon-color: rgba(253, 253, 253, 0.27);

.bet {
	display: flex;
	gap: clamp(12px, 2vh, 20px);
	align-items: center;
	position: relative;
	.info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fdfdfd;
		.label { font-size: clamp(12px, 1.8vh, 18px); }
		.value { font-size: clamp(16px, 3vh, 20px); }
	}
	.icon {
		width: $icon-size;
		height: $icon-size;
		background: $icon-color;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		font-size: clamp(8px, 1.2vh, 12px);
		color: rgba(255, 224, 127, 0.95);
		cursor: pointer;
		pointer-events: all;
		img { height: 60%; }
	}
	.spoiler {
		width: 100%;
		background: rgb(0, 0, 0);
		border-radius: $icon-size;
		position: absolute;
		display: flex;
		flex-direction: column;
		gap: 1vh;
		left: 0;
		bottom: 0;
		margin-bottom: calc($icon-size + 1vh);
	}
}