.burger {
	display: flex;
	flex-direction: column;
	align-self: center;
	gap: clamp(2px, 1vh, 10px);
	cursor: pointer;
	pointer-events: all;
	padding: 2vh;
	.el {
		width: clamp(24px, 5vh, 48px);
		height: clamp(3px, .5vh, 6px);
		background: #fdfdfd;
		border-radius: 5vw;
	}
}